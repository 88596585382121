import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import store from './store'
import router from './router'
import VueI18n from 'vue-i18n'
import zh from './language/zh-cn'
import en from './language/en-us'
Vue.use(ElementUI);
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages: {
    zh,
    en
  }
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
