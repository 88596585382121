<template>
    <el-menu mode="horizontal">
        <template v-for="item in menuList">
            <el-menu-item :class="{'now_css': $route.path === item.path}"
                    v-if="!item.subMenu || item.subMenu.length === 0"
                    :key="item.id"
                    :index="item.path"  @click="handleSelect(item)"
            >
                <span slot="title">{{ item.text }}</span>
            </el-menu-item>
            <el-submenu v-else  :key="item.id" :index="item.id + ''">
                <template slot="title">
                    <span>{{ item.text }}</span>
                </template>
                <dynamic-menu :menu-list="item.subMenu"></dynamic-menu> <!-- Recursive component for submenus -->
            </el-submenu>
        </template>
    </el-menu>
</template>

<script>
    import {scrollToTop} from '@/tools/function.js'
    export default {
        name: 'DynamicMenu',
        props: {
            menuList: {
                type: Array,
                default: () => []
            }
        },
        components: {
            DynamicMenu: () => import('@/components/DynamicMenu.vue') // Import the component recursively
        },
        methods:{
            changeLang(){
                this.$i18n.locale = 'en'
            },
            handleSelect(item) {
              this.$router.push(item.path);
              scrollToTop()
            }
        },
    };
</script>
