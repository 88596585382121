<template>
    <el-container>
        <!-- 上部导航栏 -->
        <el-header class="top_nav_menu">
            <!-- 在这里添加导航栏的内容 -->
            <slot name="header">
                <div>
                    <div class="logo" @click="$router.push('/')">
                        <img src="@/assets/logo.png" alt="EasyJabreak">
                        <img src="@/assets/logotext.png" alt="EasyJabreak">
                    </div>
                    <div class="right_nav">
                        <dynamic-menu :menu-list="menuList"></dynamic-menu>
                        <a class="github" target="_blank" href="https://github.com/EasyJailbreak/EasyJailbreak">
                            <img src="@/assets/github.svg" alt=""> <span>GitHub</span></a>

                        <div class="lang_nav">
                            <span @click="setLang('zh')" :class="{'active': langActive ==='zh'}">中</span>
                            <span @click="setLang('en')" :class="{'active': langActive ==='en'}">EN</span>
                        </div>
                    </div>
                </div>
            </slot>
        </el-header>

        <el-container class="bottom_container">
            <!-- 左侧导航栏，使用 slot 来动态插入内容 -->
            <el-aside class="aside_nav"  v-if="$slots.aside">
                <slot name="aside"></slot>
            </el-aside>

            <!-- 主要内容区域 -->
            <el-main>
                <!-- 在这里添加主要内容的代码 -->
                <slot name="main"></slot>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
  import DynamicMenu from '@/components/DynamicMenu.vue';
  export default {
    name: 'MyLayout',
    components: {
      DynamicMenu
    },
    methods:{
      setLang(lang){
        this.$i18n.locale = lang
      },
    },
    computed: {
      menuList() {
        return this.$store.state.menuList;
      },
      langActive() {
        return this.$i18n.locale
      }
    },
    created() {
      this.$store.dispatch('fetchMenuList');
    }
  };
</script>

<style lang="scss">
    body,html{padding: 0; margin: 0;  width: 100%;  height: 100%; font-family: PingFang SC;  font-size: 14px;  min-width: 1280px;}
    h2,h3,h4{margin: 0; padding: 0;}
    .aside_nav{ width: auto !important;  background-color: #fff;  position: sticky; top:94px; height: calc(100vh - 96px); z-index: 9;  }
    .el-menu--horizontal{ border-bottom: none !important;}
    .el-menu--horizontal .el-menu .el-menu-item.now_css>span{font-weight: bold;color: #409eff;}
    .el-menu--horizontal>.el-menu-item.is-active{border-bottom: none;}
    .top_nav_menu{
        position: sticky; top:0; width: 100%;  min-width: 1280px; height: 78px !important;
        z-index: 10;  background: #ffffff; padding-top: 10px;
        &>div{
            display: flex;  align-items: center;  justify-content: space-between;
            .logo{
                cursor: pointer;
                &>:nth-child(1){height: 64px;}
                &>:nth-child(2) {position: relative; top:-14px;height: 32px;}
            }
            .right_nav{
                display: flex; align-items: center;
                .github{
                    display: block; padding-left:10px;text-decoration: none;
                    span{position: relative; top:-5px;}
                }
                .lang_nav{
                    margin:-5px 0 0 30px;
                    .active{color: #409eff}
                    span{
                        display: inline-block; position: relative; cursor: pointer;
                        &::after {
                            position: relative;
                            content: '|';
                            font-size: 16px;
                            margin: 0 5px; /* 调整竖线两侧的间距 */
                            color: #e2e2e2; /* 设置竖线的颜色，可以根据需要调整 */
                        }
                        &:last-child::after {
                            content: '';
                        }
                    }
                }
                .now_css{
                    &>span{font-weight: bold;color: #409eff;}
                }
            }
            .el-menu-item.is-active{border-bottom: none;}
        }
        .el-menu.el-menu--horizontal{
            border-bottom: none;
        }
    }
    .detail_container{
        width: 100%; margin: 0 auto 30px; max-width: 960px;
         /* 飞书大体样式 */
        .heading-block{
            margin: 22px 0 8px; font-size: 22px;
            &>h2{font-weight: 600; color:#1f2329;}
        }
        .text-block{
            margin: 8px 0;color:#1f2329; font-size: 16px;
            &>h4{font-size: 16px; font-weight: 700;margin-top: 40px;}
            .title_txt{
                font-size:16px;font-weight: bold; color:#1f2329; margin-right: 5px;
            }
        }
        .docx-image-block img{max-width: 100% !important;}
        /* 飞书大体样式 */
        .effect{
            &>h4{font-size: 16px; font-weight: 700;margin-top: 40px; margin-bottom: 20px;}
            width: 960px;margin:0 auto;
            .exampleSelectorContainer{
                display:flex;background-color: rgb(140, 21, 21);
                padding: 7px;border-radius: 5px; margin-bottom: 2px;align-items: center;
                &>span{margin: 3px;  color: white;}
                .el-select{flex: 1; margin-right: 10px;}
            }
            .chatbot{
                display: flex;
                &>section{
                    flex: 1;border: 1px solid black;
                    .chatbotHeader{
                        display: flex;  align-items: center;
                        background-color: #dee1e5;  padding: 5px;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        min-height: 50px;
                        img {height: 30px;width: auto;  margin-right: 10px;}
                        &>span{font-weight: bold; font-size: larger;}
                    }
                    .output-container {
                        height: 500px;
                        &>div{
                            background-color: white; height: 100%;
                            overflow-y: auto;  display: flex;
                            flex-direction: column;
                        }
                    }
                    .userMessage {
                        background-color: #5e96fc;  color: white;
                        max-width: 80%;  border-radius: 10px;  margin: 10px;
                        padding: 10px;  align-self: flex-end;
                    }
                    .assistantMessage{
                        background-color: #f4f4f4;  color: black;  max-width: 80%;
                        border-radius: 10px;  margin: 10px;  padding: 10px;
                        align-self: flex-start;
                    }
                }
                .chatbot1{margin-right:2px;}
            }
            .who {font-weight: bold; font-size: larger;}
        }
    }
</style>
