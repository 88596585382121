<template>
  <div class="home">
  <my-layout>
    <!-- 插入主内容区的内容 -->
    <template v-slot:main>
      <p class="log_content">
        <img src="@/assets/newLog.png" alt=""/>
      </p>

      <p class="description">An easy-to-use Python framework to generate adversarial jailbreak prompts by assembling different methods</p>
      <p class="dynamic">{{describe}}</p>

      <div class="home-bottom">
        <section>
          <p class="img"><img src="@/assets/tip1.png"/></p>
          <h3>Innovative Taxonomy</h3>
          <div>Our innovative taxonomy methodically categorizes jailbreak research into three distinct streams: human design, long-tail encoding, and prompt optimization.</div>
        </section>
        <section>
          <p class="img"><img src="@/assets/tip2.png"/></p>
          <h3>Unified Framework</h3>
          <div>EasyJailbreak offers a unified architecture, integrating 11 classic jailbreak attack methods with a simple interface.</div>
        </section>
        <section>
          <p class="img"><img src="@/assets/tip3.png"/></p>
          <h3>Comprehensive Evaluation</h3>
          <div>Our platform sets a new benchmark for evaluating model security, conducting systematic assessments across popular LLM models using representative jailbreak algorithms.</div>
        </section>
      </div>
    </template>
  </my-layout>
  </div>
</template>

<script>
    import MyLayout from '@/views/MyLayout.vue'
    export default {
        name: 'App',
        components: {
          MyLayout
        },
        data() {
          return {
            describe:`EasyJailbreak is an easy-to-use Python framework designed for researchers and developers focusing on LLM security.
            Specifically, EasyJailbreak decomposes the mainstream jailbreaking process into several iterable steps: initialize mutation seeds,
            select suitable seeds, add constraint, mutate, attack, and evaluate. On this basis, EasyJailbreak provides a component for each step,
            constructing a playground for further research and attempts. More details can be found in our paper. `,
          };
        },
        methods:{},
        created(){}
    };
</script>

<style lang="scss" scoped>
  .home{
    font-family: lato, PingFang SC, Hiragino Sans GB, "微软雅黑", Arial, sans-serif;
    .log_content{
      text-align: center; margin-top: -30px;
      img{width: 35%;}
    }
    /* 描述段落的样式 */
    .description {
      font-size: 16px;
      color: #666;
      text-align: center; /* 设置文本居中 */
      margin-top: 0; /* 删除顶部间距 */
      margin-bottom: 16px;
    }

    /* 动态内容段落的样式 */
    .dynamic {
      font-style: italic;
      color: #000000;
      font-size: 18px;
      text-align: center; /* 设置文本居中 */
      word-wrap: break-word; /* 自动换行 */
      margin: 0 auto; /* 水平居中 */
      max-width: 60%; /* 设置最大宽度，以防止内容过宽 */
    }
    .home-bottom{
      display: flex; justify-content: space-between; margin: 20px auto 0; width: 1024px;
      &>section{
        margin: 0 20px;
        &>h3{text-align: center; font-size: 20px; margin-bottom: 10px;}
        &>div{color: #666;}
        .img{
          text-align: center;
          img{width: 200px;}
        }
      }
    }
  }
</style>
