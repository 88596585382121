import Vue from 'vue'
import Vuex from 'vuex'
import staticDb from '../db/staticDb.js'

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    menuList: [] // Initialize menuList in the state
  },
  getters: {},
  mutations: {
    setMenuList(state, menuList) {
      state.menuList = menuList;
      console.log('仓库执行时间')
    }
  },
  actions: {
    fetchMenuList({ commit }) {
      // Here you would fetch your menuList from an API or define it statically
      const menuList = staticDb.menuList;
      commit('setMenuList', menuList);
    }
  },
  modules: {
  }
});

export default store;


