import Vue from 'vue'
import Router from 'vue-router'
import staticDb from '../db/staticDb.js'
import HomeView from '../views/Home.vue'
Vue.use(Router);

// const fnNames = ['ReNeLLM', 'GPTFuzz', 'ICA','AutoDAN', 'PAIR',  'JailBroken', 'Cipher', 'DeepInception', 'MultiLingual', 'GCG', 'TAP'];
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

const generateRoutesFromMenu = (menuList, routes = []) => {
  menuList.forEach(item => {
    if (item.path && (!item.subMenu || item.subMenu.length === 0)) {
      routes.push({
        path: item.path,
        name: item.name,
        component: () => import(`@/views/${item.name}.vue`)
      })
      // routes.push({ path: item.path, component: resolve => require(['@/components/' + item.name], resolve) });
    }
    if (item.subMenu && item.subMenu.length > 0) {
      generateRoutesFromMenu(item.subMenu, routes);
    }
  });
  return routes;
};

const routes = generateRoutesFromMenu(staticDb.menuList);

// for(let item of fnNames){
//   routes.push({
//     path: `/details/${item}`,
//     component: () => import(`@/views/details/${item}.vue`)
//   })
// }

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: HomeView  },
    ...routes // Spread the dynamically generated routes
  ]
});

// router.beforeEach((to, from, next) => {
//    if(to.fullPath ==='/' || to.fullPath !== from.fullPath){
//        next();
//    }
// })
export default router;

